import React from 'react';
import '../styles/pages/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <main id="privacy-policy-main w-100">
      <section className="d-flex flex-column justify-content-center align-items-center">
        <h1 className="page-header">Privacy Policy</h1>
      </section>

      <section className="privacy-policy-section w-100 pb-5 d-flex flex-column">
        <h2>Introduction</h2>

        <p>Digital Employees ("Company" or "We") respects your privacy and is committed to protecting it through this policy. Our privacy policy outlines the types of information that may be collected from visitors to our website, Digital Employees.io (our "Website"), or users of our builders (our "Services"), and describes how we collect, use, maintain, protect, share, and disclose that information.</p>
        <p>This policy applies to information collected:</p>
        <ul>
          <li>On the Company’s Website.</li>
          <li>In email, text, and other electronic messages exchanged between the Company and a website visitor or user.</li>
        </ul>
        <p>This policy does not apply to any website, mobile app, service, or product that does not link to this Privacy Policy or that contains its own privacy policies.</p>
        <p>If you do not agree with our policies and practices, you can choose not to use our Services or visit our Website. This policy may change from time to time and will be periodically updated.</p>


        <h2>How We Collect Information and What Is Collected?</h2>
        <p>We collect several types of information from Website visitors and Services users, including:</p>
        <ul>
          <li>Personal information, such as your name, postal address, email address, telephone number, or any other identifier by which you may be contacted online or offline.</li>
          <li>Non-identifiable information, meaning data that cannot directly identify you.</li>
          <li>Technical information to determine your internet connection and the devices you use to access our Website and Services.</li>
        </ul>

        <p>We always seek your consent before collecting information. Information may be collected:</p>
        <ul>
          <li>Directly from you when you provide it to us.</li>
          <li>Automatically, as you navigate through the site (e.g., usage details, IP addresses, cookies, and web beacons).</li>
          <li>From third parties, such as our business partners.</li>
        </ul>

        <h2>Information You Provide to Us</h2>

        <p>The information we collect through our Website and Services includes:</p>
        <ul>
          <li>Data entered in forms (e.g., for registration, service requests, or suggestions).</li>
          <li>Records of support requests and other exchanges, including email addresses, which are archived.</li>
        </ul>

        <h2>Information We Collect Through Automatic Data Collection Technologies</h2>
        <p>As you use our Website and Services, we use automatic data collection technologies to gather session data, device details, and interaction patterns, including:</p>
        <ul>
          <li>Visit details (e.g., duration, pages visited, and usage patterns).</li>
          <li>Resources accessed on the Website or Services.</li>
          <li>Communication data (e.g., IP addresses, browser types, and operating systems).</li>
        </ul>

        <p>This information helps us improve the Website and Services, enabling us to:</p>
        <ul>
          <li>Estimate audience size and usage patterns.</li>
          <li>Store information about your activities for a smoother experience.</li>
          <li>Display information faster.</li>
          <li>Personalize your interactions based on your behavior.</li>
          <li>Recommend relevant services.</li>
        </ul>

        <p>We use cookies and web beacons to facilitate this data collection:</p>
        <ul>
          <li>Cookies: Small files placed on your computer's hard drive. You can refuse cookies through your browser settings, but disabling them may limit certain Website features.</li>
          <li>Web Beacons: Small electronic files embedded in some pages and emails to track engagement (e.g., page visits or downloads).</li>
        </ul>

        <p>While we do not collect personal information automatically, we may link non-personal data with personal information you provide.</p>


        <h2>How We Use Your Information</h2>
        <p>We may use the information we collect to:</p>
        <ul>
          <li>Present our Website and Services content to you.</li>
          <li>Provide requested information and services.</li>
          <li>Notify you about changes to our Website or Services.</li>
          <li>Enable direct interaction with chatbots and other support tools.</li>
          <li>Fulfill any purpose for which you provide the information.</li>
          <li>Contact you with additional services of interest (with your consent).</li>
        </ul>

        <p>If you prefer not to receive such communications, email us at <a href="mailto:tia@qualityx.io">tia@qualityx.io</a>.</p>


        <h2>Disclosure of Your Information</h2>
        <p>We may disclose aggregated, non-identifiable information without restrictions.</p>

        <p>We may disclose personal information:</p>
        <ul>
          <li>To fulfill the purpose for which it was provided.</li>
          <li>To contractors, service providers, and other third parties supporting our business, bound by confidentiality agreements.</li>
          <li>For any purpose disclosed at the time of collection, with your consent.</li>
        </ul>

        <p>Additionally, we may disclose your personal information:</p>
        <ul>
          <li>To comply with legal obligations, such as court orders or government requests.</li>
          <li>If necessary to protect the rights, property, or safety of the Company or others.</li>
        </ul>


        <h2>Choices About How We Use and Disclose Your Information</h2>
        <p>You control the personal information you provide to us. You can:</p>
        <ul>
          <li>Refuse all or some browser cookies.</li>
          <li>Receive alerts when cookies are being sent.</li>
          <li>Update your personal preferences at any time.</li>
        </ul>
        <p>Please note that disabling cookies may affect your access to certain Website features.</p>


        <h2>Children Under the Age of 16</h2>
        <p>Our Website is not intended for children under 16. We do not knowingly collect personal information from individuals under 16. If we learn that we have collected data from a child under 16 without verified parental consent, we will delete that information. If you believe we have collected such data, please contact us at tia@qualityx.io.</p>

        <h2>Changes to Our Privacy Policy</h2>
        <p>We will post any updates to this policy on this page, with a notice on the Website's home page. If we make significant changes to how we handle personal information, we will notify you by email using the address provided in your account.
          <br />You are responsible for ensuring that we have your most up-to-date email address and for checking this page periodically for updates.</p>

        <h2>Contact Information</h2>
        <p>If you have any questions or comments about this policy, please contact us at:<a href="mailto:tia@qualityx.io">tia@qualityx.io</a></p>

      </section>
    </main>
  )
}

export default PrivacyPolicy