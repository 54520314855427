import React, { useEffect } from 'react'
import '../styles/components/Header.css';
import { Link } from 'react-router-dom';
import QualityXLogo from '../assets/qualityX-logo.png';
import { Auth } from 'aws-amplify';

const Header = () => {
  const[user, setUser] = React.useState(undefined);

  const handleLogin = async () => {
    localStorage.setItem("redirect_url", window.location.pathname);
    await Auth.federatedSignIn();
  }

  const onSignOut = async () => {
    try {
      await Auth.signOut();
      alert("Signed out successfully");
      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
    .then(user => setUser(user))
    .catch((error) => {
      setUser(null);
      console.log(error);
    });
    setUser(false);
  }, []);

  return (
    <header className="d-flex w-100 flex-wrap justify-content-between align-items-start mb-4 border-bottom header">
      <Link to="/" className="d-flex align-items-center link-body-emphasis text-decoration-none">
        <img className="logo" src={QualityXLogo} alt="QualityX" />
        <span className="header-title"><strong>Digital Employees</strong> by QualityX</span>
      </Link>

      <ul className="nav nav-pills">
        <li className="nav-item">
          <Link className="nav-link" to="/ai-builders">
            AI Builders
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/how-it-works">
            How it Works
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/about">
            About us
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/request-ai-builder">
            Request AI Builder
          </Link>
        </li>
        {user ?
          <li>
            <Link className="nav-link-button">
              <button className="signin-button" onClick={() => onSignOut()} type="button">Sign out</button>
            </Link>
          </li>
          :
          <>
            <li>
              <Link className="nav-link-button">
                <button className="signin-button" onClick={() => handleLogin()} type="button">Sign in</button>
              </Link>
            </li>
            <li>
              <Link className="nav-link-button">
                <button className="signup-button" onClick={() => handleLogin()} type="button">Sign up</button>
              </Link>
            </li>
          </>
        }
      </ul>
    </header>
  )
}

export default Header