import React, { useEffect, useState } from 'react'
import '../styles/pages/AIBuilders.css';
import AgentProfile from '../components/AgentProfile';
import agentsData from '../app/agentsData.json'

const AIBuilders = () => {
  const [currentLiveIndex, setCurrentLiveIndex] = useState(0);
  const [currentInTrainingIndex, setCurrentInTrainingIndex] = useState(0);
  const [liveAgents, setLiveAgents] = useState([]);
  const [inTrainingAgents, setInTrainingAgents] = useState([]);
  const agentsPerPage = 3;

  const loadLiveAgents = (initialLoad = false) => {
    const agentsToLoad = agentsData.liveAgentsData.slice(currentLiveIndex, currentLiveIndex + agentsPerPage);
    let currentIndex = currentLiveIndex;
    if(initialLoad) {
      currentIndex = agentsPerPage;
      setCurrentLiveIndex(currentIndex);
      setLiveAgents(agentsToLoad);
    }
    else {
      currentIndex = currentLiveIndex + agentsPerPage;
      setCurrentLiveIndex(currentIndex);
      setLiveAgents((prevAgents) => [...prevAgents, ...agentsToLoad]);
    }
    if (currentIndex >= agentsData.liveAgentsData.length) {
      document.getElementById('load-more-live-agents').style.display = 'none';
    }
  };

  const loadInTrainingAgents = (initialLoad = false) => {
    const agentsToLoad = agentsData.inTrainingAgentsData.slice(currentInTrainingIndex, currentInTrainingIndex + agentsPerPage);
    let currentIndex = currentInTrainingIndex;
    if(initialLoad) {
      currentIndex = agentsPerPage;
      setCurrentInTrainingIndex(currentIndex);
      setInTrainingAgents(agentsToLoad);
    }
    else {
      currentIndex = currentInTrainingIndex + agentsPerPage;
      setCurrentInTrainingIndex(currentIndex);
      setInTrainingAgents((prevAgents) => [...prevAgents, ...agentsToLoad]);
    }
    if (currentIndex >= agentsData.inTrainingAgentsData.length) {
      document.getElementById('load-more-in-training-agents').style.display = 'none';
    }
  };

  useEffect(() => {
    // Load the first batch of agents on page load
    loadLiveAgents(true);
    loadInTrainingAgents(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <main id="ai-builders-main w-100">
      <section className="d-flex flex-column justify-content-center align-items-center">
        <h1 className="page-header">Your <span style={{ color: "#3BB879" }}>Everyday AI coworker</span>, a tireless problem solver you can trust</h1>
        <p className="page-subheader">
          A <span>connected workforce</span> to improve, upskill, and fortify product solutions teams
        </p>
      </section>

      <section className="w-100 d-flex flex-column justify-content-center align-items-center ai-builders-container">
        {/* Live AI Builders Header */}
        <h2 className="live-ai-builders-header"><span style={{ color: "#3BB879" }}>Live</span> AI Builders</h2>
        {/* <p className="live-ai-builders-description">
          Discover and start using our Quality Engineering AI agents today.
        </p> */}

        {/* Live AI Builders Content */}
        <div id="live-agents-container" className="section-content d-flex flex-column justify-content-center">
          <div className="d-flex flex-row justify-content-center flex-wrap gap-5" id="live-agents-row">
            {/* Agent cards will be injected here */}
            {liveAgents.map((agent, index) => (
              <AgentProfile key={index} agentData={agent} isLiveAgent={true} />
            ))}
          </div>
        </div>
        <button className="load-more-agents-btn" id="load-more-live-agents" onClick={() => loadLiveAgents()}>Load More Agents</button>

        {/* In Training Header */}
        <h2 className="live-ai-builders-header">In <span style={{ color: "#3BB879" }}>Training</span></h2>
        {/* <p className="live-ai-builders-description">
          Empowering teams with real-time AI solutions for seamless task execution and innovation
        </p> */}

        {/* In Training Content */}
        <div id="in-training-agents-container" className="section-content d-flex flex-column justify-content-center">
          <div className="d-flex flex-row justify-content-center flex-wrap gap-5" id="in-training-agents-row">
            {/* Agent cards will be injected here */}
            {inTrainingAgents.map((agent, index) => (
              <AgentProfile key={index} agentData={agent} isLiveAgent={false} />
            ))}
          </div>
        </div>
        <button className="load-more-agents-btn" id="load-more-in-training-agents" onClick={() => loadInTrainingAgents()}>Load More Agents</button>

      </section>
    </main>
  )
}

export default AIBuilders