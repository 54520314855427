import './App.css';
import { AppRoutes } from './app/routes';
import React from 'react';

function App() {
  return (
    <section>
      <AppRoutes />
    </section>
  );
}

export default App;
