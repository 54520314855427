import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ErrorPage from "../pages/ErrorPage";
import AIBuilders from "../pages/AIBuilders";
import HowItWorks from "../pages/HowItWorks";
import About from "../pages/About";
import RequestAIBuilder from "../pages/RequestAIBuilder";
import React from "react";
import AiBuilderAgent from "../components/AiBuilderAgent";
import ContactUs from "../pages/ContactUs";
import TermsOfUse from "../pages/TermsOfUse";
import PrivacyPolicy from "../pages/PrivacyPolicy";

const addHeaderFooter = (children) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export const AppRoutes = () => (
  <Routes>
    <Route path="/" element={addHeaderFooter(<Home />)} />
    <Route path="/ai-builders">
      <Route index element={addHeaderFooter(<AIBuilders />)} />
      <Route path=":agentId" element={addHeaderFooter(<AiBuilderAgent />)} />
    </Route>    
    <Route path="/how-it-works" element={addHeaderFooter(<HowItWorks />)} />
    <Route path="/about" element={addHeaderFooter(<About />)} />
    <Route path="/request-ai-builder" element={addHeaderFooter(<RequestAIBuilder />)} />
    <Route path="/contact-us" element={addHeaderFooter(<ContactUs />)} />
    <Route path="/terms-of-use" element={addHeaderFooter(<TermsOfUse />)} />
    <Route path="/privacy-policy" element={addHeaderFooter(<PrivacyPolicy />)} />
    <Route path="*" element={addHeaderFooter(<ErrorPage />)} />
  </Routes>
);