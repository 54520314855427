import React from 'react'
import '../styles/pages/RequestAIBuilder.css';
import HubspotForm from '../components/HubspotForm';

const RequestAIBuilder = () => {
  return (
    <main id="request-ai-builder-main w-100">
    <section className="d-flex flex-column justify-content-center align-items-center">
      <h1 className="page-header">Request an <span style={{ color: "#3BB879" }}>AI Builder</span></h1>
      <p className="page-subheader">
        <span>Need an AI agent?</span> <br />
        Tell us more about your needs
      </p>
    </section>

    <section className="contact-us-section w-100 pb-5 d-flex flex-column justify-content-center align-items-center">
        <HubspotForm region="na1" portalId="40167989" formId="a259fc93-c754-41a9-bf3f-7f3b26684f42" />
      </section>
  </main>
  )
}

export default RequestAIBuilder