import React from 'react';
import '../styles/components/AgentProfile.css';
import starImage from '../assets/star.png';
import starHalfImage from '../assets/star-half.png';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSelectedAgent } from '../app/redux/slices/liveAgentSlice';
import { Auth } from 'aws-amplify';

const AgentProfile = ({ agentData, isLiveAgent }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getRatingString = (rating) => {
    let ratingString = [];
    while (rating > 0) {
      rating--;
      ratingString.push(<img key={rating} src={starImage} alt="star" loading="lazy" />);
      if (rating > 0 && rating < 1) {
        ratingString.push(<img key={rating} src={starHalfImage} alt="star-half" loading="lazy" />);
        break;
      }
    }
    return ratingString;
  };

  const handleLogin = async () => {
    localStorage.setItem("redirect_url", window.location.pathname);
    await Auth.federatedSignIn();
  }

  const handleActivate = () => {
    Auth.currentAuthenticatedUser()
    .then(user => {
      navigate(agentData.link.includes('ai-builders') ? agentData.link.split('/').slice(1).join('/') : agentData.link);
      localStorage.setItem("selectedAgent", JSON.stringify(agentData));
      dispatch(setSelectedAgent(agentData));
    })
    .catch((error) => {
      handleLogin();
      console.log(error);
    });
  };

  return (
    <div className="agent-profile-card col-12 col-sm-6 col-lg-4">
      <img className={`${isLiveAgent ? 'live-agent-profile-img' : 'in-training-agent-profile-img'} agent-profile-img`} src={require(`../${agentData.image}`)} alt={agentData.name} loading="lazy" />
      <div className="agent-profile-info">
        <h5 className="agent-profile-name" style={{ minHeight: isLiveAgent ? 'auto' : '2.6vw'}}>{agentData.name}</h5>
        {isLiveAgent ? (
          <div>
            <p className="agent-profile-rating">{getRatingString(agentData.rating)}</p>
            <p className="agent-profile-executions">Executions: {agentData.executions}</p>
            <span onClick={() => handleActivate()}><Link>Activate</Link></span>
          </div>
        ) : (
          <a className="join-waitlist-btn" href={agentData.link}>
            Join WaitList
          </a>
        )}
      </div>
    </div>
  );
};

export default AgentProfile;