import React from 'react'
import '../styles/pages/HowItWorks.css';
import HowItWorksDiagram1 from '../assets/howitworks-diagram1.png';
import HowItWorksDiagram2 from '../assets/howitworks-diagram2.png';

const HowItWorks = () => {
  return (
    <main id="how-it-works-main w-100">
    <section className="d-flex flex-column justify-content-center align-items-center">
      <h1 className="page-header">Agentic <span style={{ color: "#3BB879"}}>AI Framework</span> for Quality Engineering</h1>
      <p className="page-subheader">
        <span>Everyday AI </span> for high performance teams, <span> Workstyle Analytics</span> to <br />
        improve productivity and reduce costs
      </p>
    </section>
    
    {/* How it works main content */}
    <section className="how-it-works-content d-flex flex-column justify-content-center align-items-center">
      <div className="how-it-works-diagram1-container">
        {/* <h1 className="page-header">QualityX <span style={{ color: "#3BB879"}}>Composable Design</span> Framework</h1>
        <p className="page-subheader">
          Discover and start using our Quality Engineering AI agents today.
        </p> */}
        <img className="how-it-works-diagram1" src={HowItWorksDiagram1} alt="How it works" />
      </div>
      <div className="how-it-works-diagram2-container">
        <img className="how-it-works-diagram2" src={HowItWorksDiagram2} alt="How it works" />
      </div>
    </section>

{/* Sign up section */}
    <section className="sign-up-its-free-section w-100 d-flex flex-column align-items-center" style={{ padding: "10vw 0px"}}>
      <h3 style={{ fontWeight: 700}}>Sign up. <span style={{ color: "#3BB879"}}>It's free.</span> Use it now.</h3>
      <button className="signup-button" style={{ marginTop: "1vw"}}>Sign up</button>
    </section>

  </main>
  )
}

export default HowItWorks