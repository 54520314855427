const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    //IPv6 localhost address
    window.location.hostname === '[::1]' ||
    //IPv4 localhost address
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

const awsConfig = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      scope: ["phone", "email", "profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: isLocalhost
        ? 'http://localhost:3000'
        : process.env.REACT_APP_REDIRECT_URL,
      redirectSignOut: isLocalhost
        ? 'http://localhost:3000'
        : process.env.REACT_APP_REDIRECT_URL,
      responseType: 'code',
    },
  },
};

export default awsConfig;
