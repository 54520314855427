// Slice containes user related states and actions
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedAgent: {},
  liveAgentsData: [],
};

const liveAgentSlice = createSlice({
  name: 'liveAgent',
  initialState: initialState,
  reducers: {
    setSelectedAgent: (state, action) => {
      return { ...state, selectedAgent: action.payload };
    }
  }
});

export const { setSelectedAgent } = liveAgentSlice.actions;

export default liveAgentSlice.reducer;
