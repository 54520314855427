/* eslint-disable no-undef */
import { configureStore } from '@reduxjs/toolkit';
import liveAgentReducer from './slices/liveAgentSlice.js';

export const store = configureStore({
  reducer: {
    liveAgent: liveAgentReducer
  },
  devTools: process.env.NODE_ENV !== 'production'
});
