import React from 'react';
import '../styles/components/TestimonialsCarousel.css';

const Testimonial = ({ testimonial }) => {
  return (
    <div className="testimonial">
      <img className="client-image" src={require(`../${testimonial.image}`)} alt="client-image" />
      <p className="client-name">{testimonial.author}</p>
      <p className="client-position">{testimonial.position}</p>
      <p className="client-testimonial">“{testimonial.comment}”</p>
      <p className="client-testimonial">{testimonial.quote}</p>
    </div>
  );
};

const TestimonialsCarousel = () => {
  const testimonialsData = [
    {
      id: 1,
      comment: "QualityX has improved our operations at Wajooba. Its time-saving capabilities, coupled with its role in bolstering our credibility, have been invaluable. Additionally, it significantly enhances QA productivity, ensuring our release cycles are not only smooth but also efficient.",
      quote: "With QualityX, we're empowered to consistently deliver top-notch products to our customers.",
      author: "Makarand B.",
      position: "CEO, Wajooba",
      image: "assets/wajooba-logo.png"
    },
    {
      id: 2,
      comment: "QualityX delivers exception results for Applied AI Consulting QA team. We have achieved a remarkable 50% reduction in manual testing time for our products as well as for our customers.",
      author: "Sanju Burkule",
      position: "Executive, Applied AI Consulting",
      image: "assets/aaic-logo.png"
    },
    {
      id: 3,
      comment: "QualityX has transformed our web app testing approach. The automated testing and performance capabilities enabled us to identify issues early, optimize our app’s responsiveness, and achieve faster, more reliable releases. With QualityX, we’ve accelerated our testing cycle, reduced manual effort, and gained confidence in our product’s performance under load. It’s been a game-changer for our QA process.",
      author: "GG.",
      position: "CTO, 7Targets",
      image: "assets/7ts-logo.png"
    }
  ];

  return (
    <>
      <div id="carousel-content" className="carousel-inner">
        {testimonialsData.map((testimonial, index) => (
          <div key={testimonial.id} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
            <Testimonial testimonial={testimonial} />
          </div>
        ))}
      </div>
      <div id="carousel-dots" className="carousel-indicators">
        {testimonialsData.map((testimonial, index) => (
          <button
            key={testimonial.id}
            type="button"
            data-bs-target="#testimonialCarousel"
            data-bs-slide-to={index}
            className={index === 0 ? 'active' : ''}
          ></button>
        ))}
      </div>
    </>
  );
};

export default TestimonialsCarousel;