import React from 'react';
import HubspotForm from '../components/HubspotForm';

const ContactUs = () => {
  return (
    <main id="contact-us-main w-100">
      <section className="d-flex flex-column justify-content-center align-items-center">
        <h1 className="page-header">Contact Us</h1>
        <p className="page-subheader">
          <span>We'd love to hear from you.</span><br/>
          Please fill out the form below and we'll promptly get back to you.
        </p>
      </section>

      <section className="contact-us-section w-100 pb-5 d-flex flex-column justify-content-center align-items-center">
        <HubspotForm region="na1" portalId="40167989" formId="7802c9c8-57ea-4769-bc10-9e3e4ffafe15" />
      </section>
    </main>
  )
}

export default ContactUs