// generic api calls implemented using axios
import axios from 'axios';
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
});


export const getApiCall = async (path, body) => {
  try {
    const response = await axiosInstance.post(path, body);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const postApiCall = async (path, body, options) => {
  try {
    const response = await axiosInstance.post(path, body, options);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const putApiCall = async (path, body) => {
  try {
    const response = await axiosInstance.put(path, body);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const deleteApiCall = async (path, body) => {
  try {
    const response = await axiosInstance.delete(path, body);
    return response.data;
  } catch (error) {
    throw error;
  }
}



