import React from 'react'
import '../styles/pages/About.css';
import Sponsors from '../assets/sponsors.png';
import Team from '../assets/team.png';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <main id="about-main w-100">
    <section className="d-flex flex-column justify-content-center align-items-center">
      <h1 className="page-header">Building <span style={{ color: "#3BB879" }}>the future of</span> Quality Engineering</h1>
      <p className="page-subheader">
        <span>AI Agents</span> that <span>augment</span> your Quality Engineering team
        </p>
    </section>

    <section className='sponsors-section w-100 d-flex flex-column justify-content-center align-items-center'>
      <img className='sponsors-image' src={Sponsors} alt="Sponsors" />
    </section>

    <section className="about-team-section w-100 d-flex flex-rowalign-items-center">
      <div className="team-content d-flex flex-column justif-content-start align-items-start">
        <h4>The Team</h4>
        <p>We are a passionate team of domain experts, architects and engineers who are busy building AI capabilities to solve our customers day to day product quality problems.</p>
        <p>We are building the foundation for the future of business operations testing by automating the most complex and time-consuming release QA tasks with AI builders.</p>
        <p>We are QA testers ourselves, we use QualityX daily and we sincerely hope that you will love using it as much as we do.</p>
      </div>
      <img className='team-image' src={Team} alt="Team" />
    </section>

    <section className="supercharger-qa-teams-section w-100 d-flex flex-row justify-space-between align-items-center">
      <div className="d-flex flex-column justify-content-start align-items-start" style={{ width: "46.3vw" }}>
        <div>
          <h3>Supercharging <span style={{ color: "#3BB879" }}>QA Teams</span> for Hyperstable releases</h3>
          <p>QualityX scales up QA teams with AI Builders, we do not replace QA teams and all AI builders are supervised 24/7 by real experts to guarantee upmost quality releases.</p>
        </div>
        <Link to="/ai-builders">Request an AI Agent</Link>
      </div>
      <div className="supercharger-qa-teams-actions">
        <p>AI Builders</p>
        <p>Team of 4 performs like 8</p>
        <p>Automation</p>
        <p>Track & Trace</p>

        <div className="vertical-line"></div>
        <div className="horizontal-line"></div>
      </div>
    </section>

    <section className="sign-up-its-free-section w-100 d-flex flex-column align-items-center" style={{ padding: "10vw 0px", background: "white" }}>
      <h3 style={{ fontWeight: 700 }}>Sign up. <span style={{ color: "#3BB879" }}>It's free.</span> Use it now.</h3>
      <button className="signup-button" style={{ marginTop: "1vw"}}>Sign up</button>
    </section>
  </main>
  )
}

export default About