import React from 'react'
import '../styles/pages/TermsOfUse.css';

const TermsOfUse = () => {
  return (
    <main id="terms-of-use-main w-100">
      <section className="d-flex flex-column justify-content-center align-items-center">
        <h1 className="page-header">Terms Of Use</h1>
      </section>

      <section className="terms-of-use-section w-100 pb-5 d-flex flex-column">
        <h2>Overview</h2>
        <p>These Terms of Use ("Terms of Use") apply to DigitalEmployees.io and any website where we display these terms (each, a "Site"). A Site provides online access to information about DigitalEmployees.io and the products, services, and opportunities we offer.</p>

        <p>By accessing and using a Site, you agree to these Terms of Use and to our Privacy Policy, available at <a href="https://digitalemployees.io/privacy-policy">https://digitalemployees.io/privacy-policy</a>.</p>

        <p>We reserve the right to modify these Terms of Use at any time without prior notice. Your continued use of a Site following such modifications constitutes your agreement to be bound by the revised Terms of Use.</p>

        <p>Since these Terms of Use govern your use of a Site, please read them carefully.</p>


        <h2>1. Permitted Use of a Site</h2>
        <p>You may use a Site, including the information, writings, images, and other works that you see, hear, or otherwise experience on a Site (individually or collectively, the "Content"), for non-commercial or commercial purposes and to learn about DigitalEmployees.io and the products, services, and opportunities we offer, provided your use complies with these Terms of Use.</p>

        <p>We may offer products, features, or tools that incorporate artificial intelligence, machine learning, or similar technologies ("AI Products").</p>

        <h3>1.1 Input and Output</h3>
        <p>You are responsible for any text, images, or other content you upload or submit to the AI Products ("Input") and for any content generated by the AI Products based on your Input ("Output"). You must ensure that your use of the AI Products, including the Input and Output, complies with these Terms of Use and all applicable laws.</p>

        <p>Input is not considered confidential information. Therefore, you should not include any data or information you are restricted from using or sharing (e.g., third-party confidential information). Output is also not considered confidential. Furthermore, you acknowledge that Output may not be accurate or reliable and should be independently reviewed before use or sharing.</p>

        <h3>1.2 Ownership and Rights</h3>
        <p>You are responsible for any text, images, or other content you upload or submit to the AI Products ("Input") and for any content generated by the AI Products based on your Input ("Output"). You must ensure that your use of the AI Products, including the Input and Output, complies with these Terms of Use and all applicable laws.</p>

        <p>Input is not considered confidential information. Therefore, you should not include any data or information you are restricted from using or sharing (e.g., third-party confidential information). Output is also not considered confidential. Furthermore, you acknowledge that Output may not be accurate or reliable and should be independently reviewed before use or sharing.</p>


        <h3>1.3 AI Service Providers</h3>
        <p>You retain all rights to use and exploit your Output. We retain ownership of the AI Products, including algorithms, models, and aggregated data derived from developing the AI Products. You may use your Output for any lawful purpose, including commercial purposes, in accordance with these Terms of Use.</p>

        <p>Output may not be unique, and the AI Products may generate identical or similar Output for other users. For example, if you ask, "What color is the sun?" the response might be "The sky is yellow." Such responses are not exclusive to you, and you cannot claim ownership over them.</p>


        <h3>1.4 Availability and Modifications</h3>
        <p>We do not guarantee that the AI Products will be available in all regions. We may modify or discontinue certain functionalities at our discretion without prior notice.</p>


        <h2>2. Prohibited Use of a Site</h2>
        <p>By accessing a Site, you agree not to:</p>
        <ul>
          <li>Use the Site in violation of these Terms of Use.</li>
          <li>Copy, modify, create derivative works from, reverse-engineer, or otherwise attempt to discover the source code of the Site, nor allow a third party to do so.</li>
          <li>Sell, sublicense, distribute, or transfer any right in the Site to a third party.</li>
          <li>Use automated systems (e.g., "robots," "spiders") to access the Site and send more requests than a person can reasonably generate through a web browser.</li>
          <li>Attempt to gain unauthorized access to the Site.</li>
          <li>Access the Site using any means other than the interface provided by DigitalEmployees.io.</li>
          <li>Use the Site for unlawful purposes or in violation of this Agreement.</li>
        </ul>
        <p>Unauthorized use of the Content or the Site may violate intellectual property laws.</p>


        <h2>3. Copyrights and Trademarks</h2>
        <p>Each Site is built on proprietary DigitalEmployees.io technology and contains Content protected by intellectual property laws, including copyright and trademark laws. All rights to the Content and the Site belong to DigitalEmployees.io or its licensors.</p>

        <p>You may not copy, reproduce, publish, distribute, or create derivative works from the Content without explicit permission. DigitalEmployees.io, its logos, and digital assets are trademarks of DigitalEmployees.io. The layout, design, and appearance of the Site are protected trade dress.</p>

        <p>Notifications of claimed copyright infringement should be sent to <a href="mailto:tia@qualityx.io">tia@qualityx.io</a>.</p>


        <h2>4. Links to Third-Party Websites</h2>
        <p>Links to third-party websites are provided for convenience. When you use these links, you leave the Site and are subject to the terms and privacy policies of the third-party site. DigitalEmployees.io is not responsible for the availability or content of these external sites.</p>


        <h2>5. Downloading Files</h2>
        <p>DigitalEmployees.io cannot guarantee that files available for download from a Site will be free of viruses or harmful code.</p>


        <h2>6. Disclaimers and Limitations of Liability</h2>
        <p>DigitalEmployees.io and its service providers make no representations about the suitability, accuracy, or availability of the Site or its Content. The Site and all related Content are provided "as is" without warranty.</p>

        <p>To the fullest extent allowed by law, DigitalEmployees.io disclaims all implied warranties, including those of merchantability, fitness for a particular purpose, and non-infringement.</p>

        <p>DigitalEmployees.io is not liable for any indirect, incidental, or consequential damages resulting from your use of the Site, even if advised of such possibilities. In jurisdictions that do not allow the exclusion of liability, DigitalEmployees.io's liability will be limited to $150.</p>


        <h2>7. Indemnification</h2>
        <p>You agree to indemnify and hold harmless DigitalEmployees.io, its affiliates, employees, and partners from claims or damages resulting from your use of the Site or violation of these Terms of Use.</p>

        <h2>8. Additional Terms</h2>
        <ul>
          <li><strong>Profiles:</strong> Some Sites may allow you to create public profiles. You must be at least 18 years old, provide accurate information, and comply with any community rules. We may edit or remove your profile at our discretion.</li>
          <li><strong>Access to Personal Information:</strong> You can access, correct, or delete your personal information at any time.</li>
          <li><strong>AI Products design patterns:</strong> You will not have access to their designs, system architecture, or underlying algorithms.</li>
        </ul>


        <h2>9. General Provisions</h2>
        <ul>
          <li><strong>Entire Agreement:</strong> These Terms of Use constitute the entire agreement between the parties.</li>
          <li><strong>Correction of Errors:</strong> We reserve the right to correct inaccuracies in the Content without notice.</li>
          <li><strong>Choice of Law and Forum:</strong> These Terms of Use are governed by the laws of the Commonwealth of Texas. Any disputes will be resolved in the federal or state courts of Frisco, Texas.</li>
        </ul>



      </section>
    </main>
  )
}

export default TermsOfUse